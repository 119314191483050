const Manager = () => import('../views/Manager') 
const About = () => import('../views/About')
const Homepage = () => import('../views/Homepage')
//个人设置
// const PersonalSettings = () => import('../components/UserMamager/personalsettings')
//用户管理
const UserList = () => import('../components/UserMamager/userlist')
// const AddUser = () => import('../components/UserMamager/adduser')
// const ModifyUser = () => import('../components/UserMamager/modifyuser')
//学校管理
const SchoolList = () => import('../components/SchoolMamager/schoollist')
//职务津贴管理
const PositionList = () => import('../components/JobAllowanceManager/positionlist')
const AllowanceList = () => import('../components/JobAllowanceManager/allowancelist')
//权限角色管理
const CharacterList = () => import('../components/AuthorityManager/characterlist')
const AuthorityList = () => import('../components/AuthorityManager/authoritylist')
//年级管理
const LevelList = () => import('../components/ClassGradeManager/levellist')
const ClassList = () => import('../components/ClassGradeManager/classlist')
// const ClassStudentList = () => import('../components/ClassGradeManager/classstudentlist')
// const ClassSchedule = () => import('../components/ClassGradeManager/classschedule')
//合同管理
const ContractTList = () => import('../components/ContractManager/contracttlist')
const AddContractT = () => import('../components/ContractManager/addcontractt')
const ModifyContractT = () => import('../components/ContractManager/modifycontractt')
//课程管理
const CourseClassFicationList = () => import('../components/CourseClassFicationManager/courseclassficationlist')
const CourseList = () => import('../components/CourseClassFicationManager/course')
const AllCourseList = () => import('../components/CourseClassFicationManager/courselist')
const AddCourse = () => import('../components/CourseClassFicationManager/addcourse')
const CourseContentList = () => import('../components/CourseClassFicationManager/coursecontentlist')
//学生管理
const StudentList = () => import('../components/StudentManager/studentlist')
const StudentInfo = () => import('../components/StudentManager/studentinfo')
const AddStudent = () => import('../components/StudentManager/addstudent')
const MyStudentList = () => import('../components/StudentManager/mystudentlist')
//列表管理
const ListofLists = () => import('../components/ListManager/listoflists')
//名单管理
const MemberList = () => import('../components/MemberManager/memberlist')
const AddMember = () => import('../components/MemberManager/addmember')
//教室管理
const ClassRoomList = () => import('../components/ClassRoomManager/classroomlist')
//老师排课
const PlanCourse = () => import('../components/TeacherPlanCourseManager/plancourse')
//缴费管理
const PayWayList = () => import('../components/PayManager/Component/paywaylist')
const RefundList = () => import('../components/PayManager/Component/refundlist')
//礼品管理
const GiftList = () => import('../components/GiftManager/Component/giftlist')
//活动管理
const SealTypeList = () => import('../components/SealTypeManager/Component/sealtypelist')
//仓库物品管理
const WarehouseList = () => import('../components/WarehouseManager/Component/warehouselist')
//数据统计
const StudentSigninList = () => import('../components/StatisticsManager/Component/studentsigninlist')
const PaymentStatistics = () => import('../components/StatisticsManager/Component/paymentstatistics')
const routes = 
[
    {
        path: '/Manager',
        name: 'Manager',
        component: Manager,
        redirect:'/Homepage',
        children: [
            { path: '/About', name: 'About' , component: About },   
            { path: '/Homepage', name: 'Homepage' , component: Homepage }, 
            { path: '/UserList', name: 'UserList' , component: UserList },
            // { path: '/AddUser', name: 'AddUser' , component: AddUser },
            // { path: '/ModifyUser', name: 'ModifyUser' , component: ModifyUser },
            { path: '/SchoolList', name :'SchoolList', component:SchoolList},
            { path: '/PositionList', name :'PositionList', component:PositionList},
            { path: '/AllowanceList', name :'AllowanceList', component:AllowanceList},
            { path: '/CharacterList', name :'CharacterList', component:CharacterList},
            { path: '/AuthorityList', name :'AuthorityList', component:AuthorityList},
            { path: '/LevelList', name :'LevelList', component:LevelList},
            { path: '/ClassRoomList', name :'ClassRoomList', component:ClassRoomList},
            { path: '/ClassList', name :'ClassList', component:ClassList},
            // { path: '/ClassStudentList', name :'ClassStudentList', component:ClassStudentList},
            // { path: '/ClassSchedule', name :'ClassSchedule', component:ClassSchedule},
            { path: '/ContractTList', name :'ContractTList', component:ContractTList},
            { path: '/AddContractT', name :'AddContractT', component:AddContractT},
            { path: '/ModifyContractT', name :'ModifyContractT', component:ModifyContractT},
            { path: '/CourseClassFicationList', name :'CourseClassFicationList', component:CourseClassFicationList},
            { path: '/CourseList', name :'CourseList', component:CourseList},
            { path: '/AllCourseList', name :'AllCourseList', component:AllCourseList},
            { path: '/PlanCourse', name :'PlanCourse', component:PlanCourse},
            { path: '/AddCourse', name :'AddCourse', component:AddCourse},
            { path: '/StudentList', name :'StudentList', component:StudentList},
            { path: '/StudentInfo', name :'StudentInfo', component:StudentInfo},
            { path: '/AddStudent', name :'AddStudent', component:AddStudent},
            { path: '/MemberList', name :'MemberList', component:MemberList},
            { path: '/ListofLists', name :'ListofLists', component:ListofLists},
            { path: '/AddMember', name :'AddMember', component:AddMember},
            // { path: '/PersonalSettings', name :'PersonalSettings', component:PersonalSettings},
            { path: '/CourseContentList', name :'CourseContentList', component:CourseContentList},
            { path: '/MyStudentList', name :'MyStudentList', component:MyStudentList},
            { path: '/PayWayList', name :'PayWayList', component:PayWayList},
            { path: '/RefundList', name :'RefundList', component:RefundList},
            { path: '/GiftList', name :'GiftList', component:GiftList},
            { path: '/SealTypeList', name :'SealTypeList', component:SealTypeList},
            { path: '/WarehouseList', name :'WarehouseList', component:WarehouseList},
            { path: '/StudentSigninList', name :'StudentSigninList', component:StudentSigninList},
            { path: '/PaymentStatistics', name :'PaymentStatistics', component:PaymentStatistics},
        ]
    }
]

export default routes