import Vue from "vue"
import Vuex from "vuex"
import tabRouterMoudle from './module/tabrouter'
import userInfoMoudle from './module/user'
import authorizationMoudle from  './module/authorization'
import themeMoudle from  './module/theme'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        tabRouterMoudle,
        userInfoMoudle,
        authorizationMoudle,
        themeMoudle
	}
})

export default store

