const themeMoudle = {
    namespaced: true,
    state:{
        isDayMode:true,
    },
    mutations:{
        setDayMode(state, colorobj) {
            state.isDayMode = true;
            document.documentElement.style.setProperty('--color-background', colorobj.light_color);
            document.documentElement.style.setProperty('--color-deputy-background', colorobj.darken_light_color);
            document.documentElement.style.setProperty('--color-element-content', colorobj.element_color_2);
            document.documentElement.style.setProperty('--color-divider', colorobj.divider_light_color);
            document.documentElement.style.setProperty('--color-scrollbar', colorobj.element_color_1);
        },
        setNightMode(state, colorobj) {
            state.isDayMode = false;
            document.documentElement.style.setProperty('--color-background', colorobj.night_color);
            document.documentElement.style.setProperty('--color-deputy-background', colorobj.darken_night_color);
            document.documentElement.style.setProperty('--color-element-content', colorobj.element_color_1);
            document.documentElement.style.setProperty('--color-divider', colorobj.divider_deep_color);
            document.documentElement.style.setProperty('--color-scrollbar', colorobj.element_color_2);
        }
    }
}

export default themeMoudle