import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import login from './login'
import manager from './manager'
import error from './error'
// import store from '@/store/store'

Vue.use(VueCookies)
Vue.use(VueRouter)
Vue.prototype.$http = axios

//请求根路径
// axios.defaults.baseURL="http://47.104.108.6:5000/"
axios.defaults.baseURL="https://www.spotrobot101.com:5001/"
// axios.defaults.baseURL="http://localhost:5000/"
// axios.defaults.baseURL="https://localhost:5001/"

//每个请求都会加一个验证
axios.interceptors.request.use(config => {
  config.headers.Authorization = "Bearer " + VueCookies.get('SRJWTTOKEN')
  return config
})
// 响应拦截器
axios.interceptors.response.use(config => {
  if(config.headers.authorization != undefined){
    VueCookies.set('SRJWTTOKEN',config.headers.authorization.substring(7), "7D");
  }
  return config
})

const routes = [ ...login,...manager,...error]

const router = new VueRouter({
  //history：没有/#;vue-router 默认 hash 模式 —— 使用 URL 的 hash 来模拟一个完整的 URL，于是当 URL 改变时，页面不会重新加载。 hash（#）是URL 的锚点
  mode: 'history', 
  routes
})

//路由导航守卫
router.beforeEach((to,form,next)=>{
  if(to.matched.length === 0){
    return next('/Notfound');
  }
  else{
    //登录界面不需要检验token
    if(to.path === '/login' || to.path == '/uploadfile'){
      return next();
    }
    else{
      //没有token强制跳转到登录界面
      const tokenStr = VueCookies.get('SRJWTTOKEN')
      if(!tokenStr){
        return next('/login');
      }
      else{
        return next();
        // for(var authorization of store.state.authorizationMoudle.authorizationList) {
        //     for(var item of authorization.authorities){
        //         if(item.abbreviation.toLowerCase() == to.path.substring(1, to.path.length - 1).toLowerCase()){
        //           return next();
        //         }
        //     }
        // }
      }
    }
  }
})

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
