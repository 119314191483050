const tabRouterMoudle = {
    namespaced: true,
    state:{
        openTableTabs:[],
        activeIndex:'/Homepage',
        routeDictionary:[
            { name:'Homepage', label:'首页' },
            { name:'UserList', label:'用户列表' },
            { name:'AddUser', label:'添加用户' },
            { name:'ModifyUser', label:'修改用户' },
            { name:'SchoolList', label:'学校列表' },
            { name:'PositionList', label:'职务列表' },
            { name:'AllowanceList', label:'津贴列表' },
            { name:'CharacterList', label:'角色列表' },
            { name:'AuthorityList', label:'权限列表' },
            { name:'LevelList', label:'列表' },
            { name:'ClassList', label:'班级列表' },
            { name:'ClassStudentList', label:'班级学生列表' },
            { name:'ClassSchedule', label:'班级课表' },
            { name:'ContractTList', label:'合同模板列表' },
            { name:'AddContractT', label:'添加合同' },
            { name:'ModifyContractT', label:'修改合同' },
            { name:'CourseClassFicationList', label:'课包列表' },
            { name:'CourseList', label:'课程列表' },
            { name:'AllCourseList', label:'课程列表' },
            { name:'AddCourse', label:'添加课程' },
            { name:'StudentList', label:'学生列表' },
            { name:'StudentInfo', label:'学生信息' },
            { name:'PersonalSettings', label:'个人设置' },
            { name:'CourseContentList', label:'课程内容' },
            { name:'MyStudentList', label:'我的学生' },
            { name:'MemberList', label:'名单列表' },
            { name:'AddMember', label:'新建名单' },
            { name:'PlanCourse', label:'老师排课' },
            { name:'ClassRoomList', label:'教室列表' },
            { name:'PayWayList', label:'支付方式列表' },
            { name:'RefundList', label:'退费列表' },
            { name:'GiftList', label:'礼品列表' },
            { name:'SealTypeList', label:'活动列表' },
            { name:'WarehouseList', label:'物品列表' },
            { name:'StudentSigninList', label:'签到统计' },
            { name:'PaymentStatistics', label:'缴费统计' },
        ]
    },
    mutations: {
        // 添加tabs
        addTabs (state, data) {
            for(var i = 0; i < state.openTableTabs.length; i++){
                if(state.openTableTabs[i].name == data.name){
                   return
                }
            }
            state.routeDictionary.forEach(item=>{
                if(item.name == data.name){
                    data.label = item.label
                }
            })
            data.IsVisible = false
            state.openTableTabs.push(data);
        },
        // 删除tabs
        deleteTabs (state, route) {
            let index = 0;
            for (let option of state.openTableTabs) {
                if (option.route === route) {
                    break;
                }
                index++;
            }
            state.openTableTabs.splice(index, 1);
        },
        // 设置当前激活的tab
        setActiveIndex (state, index) {
            for(var i = 0; i < state.openTableTabs.length; i++){
                if(state.openTableTabs[i].route == index){
                    state.openTableTabs[i].IsVisible = true
                }
                else
                {
                    state.openTableTabs[i].IsVisible = false
                }
            }
            state.activeIndex = index;
        },
    },
}

export default tabRouterMoudle