import Vue from 'vue';
import store from '@/store/store';
import App from './App.vue';
import router from './router'; //index.js中
import '@/assets/css/global.css';

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')