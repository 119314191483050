const Login = () => import('../views/Login') 
const uploadfile = () => import('../views/uploadfile') 
const routes = 
[
    {
        path: '/',
        redirect:'/Login'
    },
    {
        path: '/Login',
        name: 'Login',
        component: Login
    },
    {
        path: '/uploadfile',
        name: 'uploadfile',
        component: uploadfile
    }
]
export default routes