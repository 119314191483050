const authorizationMoudle = {
    namespaced: true,
    state:{
        authorizationList:JSON.parse(localStorage.getItem('766A1ADDC583BDE80D1F1D1F21550144')) || {}
    },
    mutations:{
        updateAuthorizationList (state, list) {
            localStorage.setItem('766A1ADDC583BDE80D1F1D1F21550144', JSON.stringify(list));
            state.authorizationList = list;
        },
    }
}

export default authorizationMoudle