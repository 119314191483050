const userInfoMoudle = {
    namespaced: true,
    state:{
        userInfo:{}
    },
    mutations:{
        updateUserInfo (state, userinfo) {
            state.userInfo = userinfo;
        },
    }
}

export default userInfoMoudle